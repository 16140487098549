import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Transitions from 'ui-component/extended/Transitions';
import { ThemeMode } from 'config';
import useConfig from 'hooks/useConfig';
import UnidedKingdom from 'assets/images/i18n/united-kingdom.png';
import Turkiye from 'assets/images/i18n/turkey.png';
import { getLocaleConfig } from 'utils/date/getLocale';
import { useDeepEffect } from 'utils/hooks/useEffectHooks';

const LocalizationSection = ({ sx = {}, languages = null }) => {
  const { mode, borderRadius, i18n, onChangeLocale } = useConfig();
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [localizationVisible, setLocalizationVisible] = useState(true);
  const anchorRef = useRef(null);
  const handleListItemClick = (_event, lng) => {
    onChangeLocale(lng);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useDeepEffect(() => {
    if (languages) {
      const intersection = [getLocaleConfig(), 'en', 'tr']
        .removeDuplicates()
        .filter((code) => languages.includes(code));

      if (intersection.length === 1) handleListItemClick(null, intersection[0]);

      setLocalizationVisible(intersection.length > 1);
    }
  }, [languages]);

  if (!localizationVisible) return <></>;

  return (
    <>
      <Box sx={{ ml: { xs: 0, sm: 2 }, ...sx }}>
        <Avatar
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            border: '1px solid',
            borderColor:
              mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
            bgcolor: mode === ThemeMode.DARK ? 'dark.main' : 'primary.light',
            color: 'primary.dark',
            transition: 'all .2s ease-in-out',
            '&[aria-controls="menu-list-grow"],&:hover': {
              borderColor: 'primary.main',
              bgcolor: 'primary.main',
              color: 'primary.light',
            },
            borderRadius: 0,
            width: '32px',
            height: '21px',
          }}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          alt="language"
          onClick={handleToggle}
          color="inherit"
        >
          {i18n === 'en' && (
            <img src={UnidedKingdom} alt="Engilsh" width="32" height="32" />
          )}

          {i18n === 'tr' && (
            <img src={Turkiye} alt="Turkiye" width="32" height="21" />
          )}
        </Avatar>
      </Box>

      <Popper
        placement={downMD ? 'bottom-start' : 'bottom'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 9999,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [downMD ? 0 : 0, 20],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Transitions
              position={downMD ? 'top-left' : 'top'}
              in={open}
              {...TransitionProps}
            >
              <Paper elevation={16}>
                {open && (
                  <List
                    sx={{
                      width: '100%',
                      minWidth: 200,
                      maxWidth: { xs: 250, sm: 280 },
                      borderRadius: `${borderRadius}px`,
                    }}
                  >
                    <ListItemButton
                      selected={i18n === 'tr'}
                      onClick={(event) => handleListItemClick(event, 'tr')}
                    >
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">Türkçe</Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ ml: '8px' }}
                            >
                              (TR)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                    <ListItemButton
                      selected={i18n === 'en'}
                      onClick={(event) => handleListItemClick(event, 'en')}
                    >
                      <ListItemText
                        primary={
                          <Grid container>
                            <Typography color="textPrimary">English</Typography>
                            <Typography
                              variant="caption"
                              color="textSecondary"
                              sx={{ ml: '8px' }}
                            >
                              (EN)
                            </Typography>
                          </Grid>
                        }
                      />
                    </ListItemButton>
                  </List>
                )}
              </Paper>
            </Transitions>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default LocalizationSection;
